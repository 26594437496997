var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-trace-list"},[_c('div',{staticClass:"store-trace-list-reporting"},[_c('div',{staticClass:"store-trace-list-reporting-header"},[_c('div',{staticClass:"store-trace-list-reporting-header-left"},[_c('i',{staticClass:"back",on:{"click":_vm.goBack}}),_c('div',{staticClass:"progress"},[_c('el-progress',{attrs:{"text-inside":true,"stroke-width":15,"color":"#4587FF","percentage":_vm.progress}})],1)]),_c('div',{staticClass:"store-trace-list-reporting-header-title"},[_vm._v("原创存证")]),_c('div',{staticClass:"store-trace-list-reporting-header-history",on:{"click":_vm.toHistory}},[_vm._v(" 历史存证 ")]),_c('div',{staticClass:"store-trace-list-reporting-header-refresh",on:{"click":_vm.refresh}},[_c('img',{attrs:{"src":require('assets/images/refresh-gray.png'),"alt":""}})])]),_c('div',{staticClass:"store-trace-list-reporting-reportform"},[_c('div',{staticClass:"store-trace-list-reporting-reportform-table"},[_c('el-table',{staticStyle:{"width":"1185px","margin":"0 auto"},attrs:{"data":_vm.reportData,"height":"680"}},[_c('el-table-column',{attrs:{"prop":"","label":"序号","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.row.status;
var index = ref.$index;
return [_c('div',{class:['states', status === 1 ? 'orange' : status === 0 ? 'blue' : status === 2 ? 'green' : 'gray']},[_vm._v(" "+_vm._s(status === 1 ? '未存证' : status === 0 ? '存证中' : status === 2 ? '完成' : '失败')+" ")]),_c('div',[_vm._v(_vm._s(index + 1))])]}}])}),_c('el-table-column',{attrs:{"prop":"filename","label":"文件名"}}),_c('el-table-column',{attrs:{"width":"85"}}),_c('el-table-column',{attrs:{"prop":"fs","label":"数据大小","width":"150","sortable":""}}),_c('el-table-column',{attrs:{"prop":"price","label":"价格","width":"130","sortable":""}}),_c('el-table-column',{attrs:{"label":"上传时间","width":"180","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var createTime = ref.row.createTime;
return [_vm._v(" "+_vm._s(createTime.split("T")[0] + " " + createTime.split("T")[1])+" ")]}}])}),_c('el-table-column',{attrs:{"label":"状态","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"status"},[(scope.row.status == 1)?_c('img',{attrs:{"src":require('assets/images/error.png'),"alt":""}}):_c('img',{attrs:{"src":require('assets/images/success.png'),"alt":""}})])]}}])}),_c('el-table-column',{attrs:{"width":"50"}}),_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ref_row = ref.row;
            var id = ref_row.id;
            var filename = ref_row.filename;
            var url = ref_row.url;
            var provePath = ref_row.provePath;
            var officialProve = ref_row.officialProve;
            var i = ref.$index;
            var status = ref.status;
return [_c('div',{staticClass:"operate"},[(_vm.completeStatus(status))?_c('span',{staticClass:"zip",on:{"click":function($event){return _vm.downloadZip(id, filename)}}}):_vm._e(),_c('span',{staticClass:"report",on:{"click":function($event){$event.stopPropagation();return _vm.selectMenu(i)}}},[_c('div',{class:['submenu', _vm.subIndex === i ? 'submenu-active' : ''],on:{"click":_vm.selectMenuItem}},[_c('span',{attrs:{"data-subitem":url}},[_vm._v("源文件")]),_c('span',{attrs:{"data-subitem":provePath}},[_vm._v("官方证书")]),_c('span',{attrs:{"data-subitem":officialProve}},[_vm._v("可信官方证书")])])])])]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }